<template>
  <div id="app" @click="menuMobileShow = false">
    <!-- 顶部导航栏 -->
    <div id="navbar" class="flex align-center justify-between">
      <img class="logo" src="/logo1.png" alt="logo" />
      <img class="logo-mobile" src="/logo2.png" alt="logo" />
      <!-- pc端 -->
      <ul class="navbar-selector flex f20">
        <li
          class="navbar-selector-options relative flex"
          :class="{ 'navbar-active': index == menuCurrent }"
          v-for="(item, index) in menuList"
          :key="index"
        >
          <router-link class="navbar-item pointer" :to="item.route">{{
            item.name
          }}</router-link>
        </li>
      </ul>
      <!-- mobile端 -->
      <div class="menu absolute" :class="{ 'menu-active': menuMobileShow }">
        <img
          class="menu-icon"
          src="../src/assets/icon13.png"
          @click.stop="menuMobileShow = !menuMobileShow"
        />
        <div class="menu-list flex flex-cos">
          <router-link
            class="menu-list-item text-align oneline"
            :class="{ 'menu-item-active': index == menuCurrent }"
            :to="item.route"
            v-for="(item, index) in menuList"
            :key="index"
            >{{ item.name }}</router-link
          >
        </div>
      </div>
    </div>
    <!-- 内部区域 -->
    <router-view />
    <!-- 底部栏 -->
    <div id="footer" :style="[bgStyle]">
      <div class="footer w1330">
        <div class="footer-title text-align b800">CONTACT US</div>
        <!-- <ul class="footer-list flex">
          <li class="footer-list-item">
            <div class="footer-list-item-title f26">Profile</div>
            <div class="footer-list-item-child">
              <div>Brand</div>
              <div>Team</div>
              <div>About us</div>
            </div>
          </li>
          <li class="footer-list-item">
            <div class="footer-list-item-title f26">Services</div>
            <div class="footer-list-item-child">
              <div>3D model</div>
              <div>3D level</div>
              <div>Animation</div>
              <div>Vfx</div>
            </div>
          </li>
        </ul> -->
        <div class="footer-company absolute" @click="preImage">
          <div class="location"></div>
        </div>
        <ul class="footer-contact flex flex-end">
          <li
            class="footer-contact-box relative flex align-center justify-center pointer"
            v-for="item in links"
            :key="item.id"
            @click="navTo(item)"
          >
            <img class="footer-contact-icon" :src="item.image" />
            <div
              class="footer-show-box absolute"
              v-if="item.category === 'qrode'"
            >
              <img class="footer-show-box-icon" :src="item.qrcode_image" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- pc预览图片弹窗 -->
    <preImage
      :show="show"
      :list="template.cityPhotos"
      :current="0"
      @close="show = false"
    ></preImage>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

import preImage from "./components/preImage.vue";
import { ImagePreview } from "vant";

export default {
  data() {
    return {
      menuMobileShow: false,
      show: false,
      links: [],
    };
  },
  components: {
    preImage,
  },
  computed: {
    ...mapState(["template", "menuList", "menuCurrent"]),
    bgStyle() {
      return {
        // 'background-image': this.template.footer && `url(${this.$header + this.template.footer})`
        "background-image": "url(/map.png)",
      };
    },
  },
  created() {
    this.getConfig();
  },
  mounted() {
    window.addEventListener(
      "scroll",
      (e) => {
        if (window.innerWidth < 750) {
          if (window.pageYOffset > 100) {
            document.querySelector("#navbar").style.background = "#363636";
          } else
            document.querySelector("#navbar").style.background = "transparent";
        }
      },
      true
    );
    this.links = [
      {
        id: 5,
        image: "/uploads/20220607/5af47a0747fdcf84f0d224af817727b9.png",
        qrcode_image: "",
        url: "mailto:nicolezhang@netherwind.com.cn",
        weight: 10,
        category: "link",
        status: "normal",
        createtime: 1654588343,
        category_text: "Category link",
        status_text: "Status normal",
      },
      {
        id: 10,
        image: "/uploads/20220607/d53ea50a69d6631e998225de547e01b9.png",
        qrcode_image: "",
        url: "https://www.artstation.com/netherwind/profile",
        weight: 7,
        category: "link",
        status: "normal",
        createtime: 1654588810,
        category_text: "Category link",
        status_text: "Status normal",
      },
      {
        id: 7,
        image: "/uploads/20220607/db52e7528b73a14e100b17d59cf82880.png",
        qrcode_image: "",
        url: "https://www.facebook.com/profile.php?id=61560141117568",
        weight: 8,
        category: "link",
        status: "normal",
        createtime: 1654588488,
        category_text: "Category link",
        status_text: "Status normal",
      },
      {
        id: 6,
        image: "/icons/x.png",
        qrcode_image: "",
        url: "https://x.com/chngdulngf92718",
        weight: 9,
        category: "link",
        status: "normal",
        createtime: 1654588425,
        category_text: "Category link",
        status_text: "Status normal",
      },

      {
        id: 9,
        image: "/uploads/20220607/6a4edb7a31bf3f89209e73d0cfe3ce5c.png",
        qrcode_image: "",
        url: "https://www.instagram.com/netherwind.cn/",
        weight: 6,
        category: "link",
        status: "normal",
        createtime: 1654588776,
        category_text: "Category link",
        status_text: "Status normal",
      },
      {
        id: 8,
        image: "/icons/xhs.png",
        qrcode_image: "/icons/xhs-code.png",
        url: "",
        weight: 5,
        category: "qrode",
        status: "normal",
        createtime: 1654588517,
        category_text: "Category link",
        status_text: "Status normal",
      },
      {
        id: 11,
        image: "/icons/dy.png",
        qrcode_image: "/icons/dy-code.png",
        url: "",
        weight: 4,
        category: "qrode",
        status: "normal",
        createtime: 1654588849,
        category_text: "Category link",
        status_text: "Status normal",
      },
      {
        id: 12,
        image: "/uploads/20220607/271d18e859126524c6468d3dab1a8a8d.png",
        qrcode_image: "/icons/wx-code.png",
        url: "",
        weight: 3,
        category: "qrode",
        status: "normal",
        createtime: 1654588980,
        category_text: "Category qrode",
        status_text: "Status normal",
      },
    ];
  },
  methods: {
    ...mapActions(["getConfig"]),
    navTo(item) {
      if (item.category === "link") window.open(item.url);
    },
    // 预览图片
    preImage() {
      if (window.innerWidth > 750) {
        this.show = true;
      } else {
        //mobile端预览图片
        ImagePreview({
          images: this.template.cityPhotos.map((item) => {
            return item.image;
          }),
          showIndicators: true,
        });
      }
    },
  },
};
</script>
<style lang="less">
@font-face {
  font-family: "ArialBlack";
  src: url("../src/common/font/Arial\ Black.70b30b8a.TTF");
}

@font-face {
  font-family: "Saira";
  src: url("../src/common/font/Saira-ExtraBold.f0e8a2d7.ttf");
}

@import url("../src/common/style/index.css");

.el-message {
  min-width: 100px !important;
}

#navbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999999;
  height: 71px;
  padding: 0 75px;
  background-color: #fff;
}

.logo {
  position: relative;
  top: 15px;
  width: 439px;
  height: fit-content;
}

.logo-mobile {
  width: 110px;
  height: 24px;
  display: none;
}

.menu {
  display: none;
}

.navbar-selector {
  height: 100%;
}

.navbar-selector-options {
  margin-left: 60px;
  letter-spacing: 3px;
}

.navbar-active {
  border-bottom: 6px solid #dd4043;
}

.navbar-item {
  font-family: AdobeHeitiStd-Regular;
  height: 100%;
  line-height: 71px;
  color: #7d7d7d;
}

.navbar-options-child {
  left: 50%;
  top: 71px;
  transform: translateX(-50%);
  padding: 18px 35px;
  background-color: rgba(0, 0, 0, 0.21);
  display: none;
}

.navbar-options-child-item {
  font-family: AdobeHeitiStd-Regular;
  white-space: nowrap;
}

.navbar-options-child-item:nth-of-type(n + 2) {
  margin-left: 35px;
}

.navbar-selector-options:hover .navbar-options-child {
  display: block;
}

#footer {
  position: relative;
  height: 800px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  background-color: #f5f5f7;
}
@keyframes beat {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30%);
  }

  100% {
    transform: translateY(0);
  }
}
.location {
  animation: beat 1.22s infinite;
  position: absolute;
  content: "";
  background-image: url("./assets/location.png");
  background-size: 100%;
  left: 3%;
  top: -60%;
  width: 20%;
  height: 66%;
}
.footer-title {
  padding: 90px 0;
  font-size: 80px;
  line-height: 1.15;
  font-family: Saira;
  color: #f96f2f;
}

.footer-list-item {
  margin-right: 140px;
  color: #363636;
}

.footer-list-item-child {
  margin-top: 50px;
  font-size: 22px;
}

.footer-list-item-child div {
  margin-bottom: 20px;
  cursor: pointer;
}

.footer-company {
  // background: url(../src/assets/icon9.png);
  background-size: 100%;
  background-repeat: no-repeat;
  top: 50%;
  left: calc(50% + 429px);
  transform: translateY(-120%);
  width: 7.6vw;
  height: 2.3vw;
  max-width: 152px;
  max-height: 46px;
  cursor: pointer;
}

@media screen and (max-width: 1920px) {
  .footer-company {
    left: 73%;
  }
}

.footer-contact {
  margin-top: 135px;
}

.footer-contact-box {
  margin-left: 35px;
  width: 72px;
  height: 72px;
  background-image: linear-gradient(#ffffff, hsl(0, 0%, 100%)),
    linear-gradient(#dddddd, #dddddd);
  box-shadow: 0px 9px 18px 0px rgba(6, 0, 1, 0.09);
  border-radius: 50%;
}

.footer-contact-icon {
  width: 40px;
  height: 40px;
  object-fit: scale-down;
  transition: 1s ease;
}

.footer-show-box {
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 150px;
  padding: 10px;
  border-radius: 10px;
  background: #fff;
  display: none;
}

.footer-show-box-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer-contact-box:hover .footer-show-box {
  display: block;
}

.footer-contact-box:hover {
  background: #eee;
}

@media screen and (max-width: 750px) {
  #navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 20px;
    height: 50px;
    background: transparent;
  }

  .logo {
    display: none;
  }

  .logo-mobile {
    display: block;
  }

  .navbar-selector {
    display: none;
  }

  .menu {
    display: flex;
    top: 20px;
    right: -200px;
    transition: 0.3s ease;
  }

  .menu-active {
    right: 0;
  }

  .menu-icon {
    height: 12px;
    width: 14px;
  }

  .menu-list {
    margin-left: 20px;
    max-height: 400px;
    overflow: scroll;
  }

  .menu-list-item {
    width: 200px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.6);
    line-height: 50px;
    white-space: nowrap;
  }

  .menu-list-item:nth-of-type(n + 2) {
    border-top: 1px solid rgba(0, 0, 0, 0.21);
  }

  .menu-item-active {
    background: rgba(33, 97, 201, 0.5);
    color: #fff;
  }

  #footer {
    height: 197px;
  }

  .footer {
    width: 100%;
  }

  .footer-title {
    padding: 20px 0;
    font-size: 20px;
  }

  .footer-list {
    display: none;
  }

  .footer-contact {
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 192px;
  }

  .footer-contact-box {
    margin: 0 16px 16px 0;
    width: 34px;
    height: 34px;
  }

  .footer-contact-box:nth-of-type(4n) {
    margin: 0 0 16px 0;
  }

  .footer-contact-icon {
    width: 19px;
    height: 19px;
    object-fit: contain;
  }

  .footer-show-box {
    bottom: 40px;
    width: 80px;
    height: 80px;
    padding: 5px;
    border-radius: 5px;
  }
}
</style>
