function timeForMat(time) {
    var timer = new Date(time * 1000)
    var year = timer.getFullYear()
    var month = timer.getMonth() + 1
    month = month < 10 ? '0' + month : month
    var day = timer.getDate()
    day = day < 10 ? '0' + day : day
    return `${year}-${month}-${day}`
}
export default {
    timeForMat
}