<template>
    <div class="pop-box flex align-center justify-center" v-if="show" @touchmove.stop.prevent @click="$emit('close')">
        <div class="pop-button pop-before absolute pointer" @click.stop="preCurrentChange(-1)"></div>
        <img class="pop-box-image" v-if="list[index]" :src="list[index].image">
        <div class="pop-button pop-after absolute pointer" @click.stop="preCurrentChange(1)"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            index: 0
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        current: {
            type: Number,
            default: 0
        },
        list: {
            type: Array,
            default: []
        }
    },
    created() {
        this.index = this.current
    },
    watch: {
        current: function (e) {
            this.index = e
        }
    },
    methods: {
        preCurrentChange(step) {
            var idx = this.index + step
            if (idx < 0 || idx > this.list.length - 1) {
                // this.$message({
                //     type: 'warning',
                //     message: '没有更多数据',
                //     offset: 100
                // });
                this.index = 0
            } else this.index = idx
        }
    }
}
</script>
<style lang="less">
@keyframes aos_load {
    0% {
        background: rgba(0, 0, 0, 0);

    }

    100% {
        background: rgba(0, 0, 0, .7);

    }
}

@keyframes aos_scale {
    0% {
        transform: scale(.8);
    }

    100% {
        transform: scale(1)
    }
}

.pop-box {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .7);
    z-index: 999999;
    animation: aos_load 1s ease;
}

.pop-box-image {
    min-height: 80%;
    max-height: 100%;
    animation: aos_scale .6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.pop-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 1) url(../assets/arrow-left-white.png) no-repeat center / 10px auto;
    opacity: .7;
}

.pop-button:hover {
    opacity: 1;
}

.pop-before {
    left: 30px;
}

.pop-after {
    right: 30px;
    transform: rotate(180deg);
}
</style>
