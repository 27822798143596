<template>
  <div class="home">
    <!-- banner图 -->
    <div class="swiper">
      <div class="banner flex flex-cos align-center justify-center">
        <img class="banner1" src="../assets/logo1.png" alt="">
        <img class="banner2" src="../assets/logo2.png" alt="">
      </div>
    </div>
    <div class="home-box w1330">
      <div class="home-title aos-2 text-align b800">About Us</div>
      <p class="home-tips aos-1">{{ aboutUs.detail }}</p>
      <div class="years-box aos-1 flex flex-wrap">
        <div class="years-box-item flex flex-cos align-center" v-for="item in history" :key="item.id">
          <div class="year-circle f-color1 f28 text-align b800 pointer" @click="preImage(item)">{{ item.years }}</div>
          <p class="year-content text-align">
            {{ item.detail }}
          </p>
        </div>
      </div>
      <ul class="data-box aos-2 flex flex-wrap">
        <li class="data-item flex flex-cos align-center">
          <span class="data-item-title">ARTISTS</span>
          <span class="data-item-num">{{ aboutUs.artistts }}</span>
        </li>
        <li class="data-item flex flex-cos align-center">
          <span class="data-item-title">PROJECTS</span>
          <span class="data-item-num">{{ aboutUs.projects }}</span>
        </li>
        <li class="data-item flex flex-cos align-center">
          <span class="data-item-title">CLIENTS</span>
          <span class="data-item-num">{{ aboutUs.clients }}</span>
        </li>
        <li class="data-item flex flex-cos align-center">
          <span class="data-item-title">YEARS</span>
          <span class="data-item-num">{{ aboutUs.years }}</span>
        </li>
      </ul>
    </div>
    <!-- pc端 -->
    <div class="slider aos-1 relative f-color1">
      <div class="pc-title">ART DIRECTOR / CO FOUNDER</div>
      <div class="slider-box flex slider-box-pc">
        <div class="lex flex-cos align-center" v-for="item in teamList.slice(0,2)" :key="item.id">
          <div class="slider-title b800">{{ item.name }}</div>
          <!-- <div class="slider-ftitle">{{ item.post_name }}</div> -->
          <div class="slider-main flex align-center">
            <div class="slider-left relative flex flex-cos flex-end">
              <div class="slider-left-title b800">{{ item.works }}</div>
              <div v-for="(name1, index) in item.post_name.split('|')" :key="index+'-1'" class="slider-left-floor flex align-center flex-end" v-html="name1">
              </div>
              <!-- <div class="slider-left-floor flex align-center flex-end">
                <span>EA Games</span>
                Battlefield 4
              </div> -->
              <h2 class="slider-left-floor1 f30 b800">Netherwind</h2>
              <div v-for="(name2, index) in item.detail.split('|')" :key="index+'-2'" class="slider-left-floor flex align-center flex-end" v-html="name2">
              </div>
            </div>
            <div class="slider-right">
              <div class="slider-right-box flex align-center justify-center">
                <img :src="item.image">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="arrow left-icon absolute" @click="swiperChange(-1)"></div>
      <div class="arrow right-icon absolute" @click="swiperChange(1)"></div> -->
    </div>
    <!-- mobile端 -->
    <div class="slider-mobile aos-1 relative f-color1">
      <div class="slider-box flex slider-box-mobile" :style="[watchStyle]" @touchstart="touchStart">
        <div class="slider-contain flex flex-cos align-center" v-for="item in teamList" :key="item.id">
          <div class="mobile-title">ART DIRECTOR / CO FOUNDER</div>
          <div class="slider-title b800">{{ item.name }}</div>
          <!-- <div class="slider-ftitle">{{ item.post_name }}</div> -->
          <div class="slider-right-box flex align-center justify-center">
            <img :src="item.image">
          </div>
          <div class="slider-left-title b800 text-align">{{ item.works }}</div>
          <div v-for="(name1, index) in item.post_name.split('|')" :key="index+'-1'" class="slider-left-floor flex align-center flex-end" v-html="name1">
           <!-- {{ name1 }} -->
          </div>
          <h2 class="slider-left-floor1 f30 b800">Netherwind</h2>
          <div v-for="(name2, index) in item.detail.split('|')" :key="index+'-2'" class="slider-left-floor text-align" v-html="name2">
            <!-- {{ name2 }} -->
          </div>
        </div>
      </div>
    </div>
    <div style="overflow:hidden">
      <div class="service  w1330">
        <div class="home-title aos-2 text-align b800">SERVICES</div>
        <div class="service-box aos-1 flex flex-wrap">
          <div class="service-item flex flex-cos align-center justify-between" v-for="item in serviceList"
            :key="item.id">
            <img :src="item.image">
            <span class="oneline">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="projects">
        <div class="dot-active relative">
          <div class="rectangle rectangle1"></div>
          <div class="rectangle rectangle2"></div>
          <div class="triangle"></div>
          <div class="trianfoot"></div>
        </div>
        <img class="projects-logo aos-5" src="../assets/logo3.png">
        <div class="home-title aos-2 text-align b800">PROJECTS</div>
        <!-- pc端 -->
        <ul class="grid-box aos-1">
          <li class="grid-item flex">
            <div class="grid-item-over relative pointer" v-for="item in projectList.slice(0, 4)" @click="viewLink(item)" :key="item.id">
              <img class="grid-item-child absolute" :src="item.image">
            </div>
          </li>
          <li class="grid-item flex">
            <div class="grid-item-over relative pointer" v-for="item in projectList.slice(4, 9)" @click="viewLink(item)" :key="item.id">
              <img class="grid-item-child absolute" :src="item.image">
            </div>
          </li>
          <li class="grid-item flex">
            <div class="grid-item-over relative pointer" v-for="item in projectList.slice(9, 13)" @click="viewLink(item)" :key="item.id">
              <img class="grid-item-child absolute" :src="item.image">
            </div>
          </li>
          <li class="grid-item flex">
            <div class="grid-item-over relative pointer" v-for="item in projectList.slice(13, 18)" @click="viewLink(item)" :key="item.id">
              <img class="grid-item-child absolute" :src="item.image">
            </div>
          </li>
          <li class="grid-item flex">
            <div class="grid-item-over relative pointer" v-for="item in projectList.slice(18, 22)" @click="viewLink(item)" :key="item.id">
              <img class="grid-item-child absolute" :src="item.image">
            </div>
          </li>
        </ul>
        <!-- mobile端 -->
        <ul class="grid-box-mobile aos-1">
          <li class="grid-item flex">
            <div class="grid-item-over relative" v-for="item in projectList.slice(0, 3)" @click="viewLink(item)" :key="item.id">
              <img class="grid-item-child absolute" :src="item.image">
            </div>
          </li>
          <li class="grid-item flex">
            <div class="grid-item-over relative" v-for="item in projectList.slice(3, 7)" @click="viewLink(item)" :key="item.id">
              <img class="grid-item-child absolute" :src="item.image">
            </div>
          </li>
          <li class="grid-item flex">
            <div class="grid-item-over relative" v-for="item in projectList.slice(7, 10)" @click="viewLink(item)" :key="item.id">
              <img class="grid-item-child absolute" :src="item.image">
            </div>
          </li>
          <li class="grid-item flex">
            <div class="grid-item-over relative" v-for="item in projectList.slice(10, 14)" @click="viewLink(item)" :key="item.id">
              <img class="grid-item-child absolute" :src="item.image">
            </div>
          </li>
          <li class="grid-item flex">
            <div class="grid-item-over relative" v-for="item in projectList.slice(14, 17)" @click="viewLink(item)" :key="item.id">
              <img class="grid-item-child absolute" :src="item.image">
            </div>
          </li>
          <li class="grid-item flex">
            <div class="grid-item-over relative" v-for="item in projectList.slice(17, 21)" @click="viewLink(item)" :key="item.id">
              <img class="grid-item-child absolute" :src="item.image">
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="partner-main w1330">
      <div class="home-title aos-2 text-align b800">OUR PARTNERS</div>
      <ul class="partner flex aos-1 flex-wrap">
        <li class="partner-item flex align-center justify-center" v-for="item in partnerList" :key="item.id">
          <img class="partner-item-img" :src="item.image">
        </li>
      </ul>
    </div>
    <!-- pc预览图片弹窗 -->
    <preImage :show="show" :list="images" :current="0" @close="show = false"></preImage>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import preImage from '../components/preImage.vue'
import { ImagePreview } from 'vant';

export default {
  name: 'Home',
  data() {
    return {
      aboutUs: {},
      history: [],
      teamList: [],
      teamCurrent: 0,
      serviceList: [],
      projectList: [],
      partnerList: [],
      timev: null,
      images: [],
      show: false, //预览弹窗开关
    }
  },
  components: {
    preImage
  },
  computed: {
    ...mapState(['template']),
    watchStyle() {
      return {
        transform: `translateX(-${this.teamCurrent * 100}%)`,
        transition: `.6s ease-in-out`
      }
    }
  },
  created() {
    this.setNavbarCurrent(this.$route.path)
    this.getAboutUs()
    this.getTeamList()
    this.getServiceList()
  },
  mounted() {
    this.initPos() //获取布局信息并设置动画
  },
  methods: {
    ...mapActions(['setNavbarCurrent']),
    viewLink(item){
      // console.log(item);
      window.open(item.name)
    },
    initPos() {
      this.$api.addScrollListener(['.aos-1', '.aos-2', '.aos-5'])
    },
    //关于我们
    async getAboutUs() {
      var data = (await this.$api.Get('index/aboutUs')).data
      this.aboutUs = data.about
      this.history = data.history
    },
    //团队列表
    async getTeamList() {
      var res = await this.$api.Get(`index/teamList?page=1&limit=999999`)
      this.teamList.push(...res.data)
      // this.setSwiperAuto().start()
    },
    //服务、项目、合作伙伴列表
    async getServiceList() {
      var res = await this.$api.Get('index/resourceList?page=1&limit=99999')
      this.serviceList = res.data.service
      this.projectList = res.data.projects
      this.partnerList = res.data.partner
    },
    //轮播切换(pc)
    swiperChange(step) {
      this.setSwiperAuto().clear()
      var cur = this.teamCurrent + step
      if ((step < 0 && cur >= 0) || (step > 0 && cur < this.teamList.length)) {
        this.teamCurrent = cur
      } else {
        if (step < 0) this.teamCurrent = this.teamList.length - 1
        else this.teamCurrent = 0
      }
      this.setSwiperAuto().start()
    },
    //PC端轮播切换(PC)
    mouseDown(target) {
      var positionX = 0;
      //鼠标移动
      document.onmousemove = e => {
        //移动距离
        positionX = e.clientX - target.clientX;
        this.moveRule(positionX).moving()
      };
      document.onmouseup = e => {
        document.onmousemove = null;
        document.onmouseup = null;
        this.moveRule(positionX).ending()
      };
    },
    //手机端轮播切换(mobile)
    touchStart(target) {
      var positionX = 0;
      //鼠标移动
      document.ontouchmove = e => {
        //移动距离
        positionX = e.changedTouches[0].clientX - target.changedTouches[0].clientX;
        this.moveRule(positionX).moving()
      };
      document.ontouchend = e => {
        document.ontouchmove = null;
        document.ontouchend = null;
        this.moveRule(positionX).ending()
      };
    },
    //移动规则判断
    moveRule(positionX) {
      return {
        moving: () => {
          this.watchStyle.transform = `translateX(${-(this.teamCurrent * window.innerWidth - positionX)}px)`
          this.watchStyle.transition = `0s ease-in-out`
          this.$forceUpdate()
          this.setSwiperAuto().clear()
        },
        ending: () => {
          if (Math.abs(positionX) > window.innerWidth / 4) {
            if (positionX < 0 && this.teamCurrent < this.teamList.length - 1) {
              this.teamCurrent++
            }
            if (positionX > 0 && this.teamCurrent > 0) {
              this.teamCurrent--
            }
          }
          this.watchStyle.transform = `translateX(-${this.teamCurrent * 100}%)`
          this.watchStyle.transition = `.6s ease-in-out`
          this.$forceUpdate()
          this.setSwiperAuto().start()
        }
      }
    },
    //设置轮播
    setSwiperAuto() {
      return {
        start: () => {
          this.timev = setInterval(() => {
            this.swiperChange(1)
          }, 4000);
        },
        clear: () => {
          clearInterval(this.timev)
        }
      }
    },
    // 预览图片
    preImage(item) {
      if (window.innerWidth > 750) {
        //pc端预览图片
        this.images = item.images.split(',').map(it => {
          return {
            image: it
          }
        })
        this.show = true
      } else {
        //mobile端预览图片
        var images = item.images.split(',')
        ImagePreview({
          images,
          showIndicators: true
        });
      }
    }
  }
}
</script>
<style lang="less" scoped>
.mobile-title{
  color: #f36432;
  font-size: 16px;
}
.pc-title{
  text-align: center;
  // font-family: Saira;
  color: #f36432;
  font-size: 40px;
  padding-top: 10px;
}
.slider-box-pc{
  justify-content: space-around;
  .slider-left{
    padding-right: 0;
  }
  .slider-main{
    padding-top: 0;
  }
  .slider-title{
    text-align: center;
    padding-bottom: 0;
    padding-top: 5px;
  }
  .slider-left-floor1{
    margin-top: 30px;
  }
}

.swiper {
  height: calc(100vh - 71px);
  overflow: hidden;
}

.banner {
  width: 100%;
  height: 100%;
  background: #4c4c4c;
}

@keyframes _left-in {
  100% {
    transform: translateX(0);
  }
}

@keyframes _right-in {
  70% {
    transform: translate(0, 0);
  }

  90% {
    transform: translate(0, 20%);
  }

  100% {
    transform: translate(0, 0);
  }
}

.banner1 {
  position: relative;
  width: 864px;
  height: 414px;
  right: -126px;
  transform: translateX(150%);
  animation: _left-in 1s ease-in-out forwards;
}

.banner2 {
  width: 1135px;
  height: 172px;
  position: relative;
  top: -55px;
  transform: translateX(-150%);
  animation: _right-in 2s .4s ease-in-out forwards;
}

.home-title {
  padding: 65px 0 10px 0;
  font-size: 80px;
  line-height: 1.15;
  font-family: Saira;
  color: #f96f2f;
}

.home-tips {
  font-family: ArialBlack;
  font-size: 41px;
  font-weight: 700;
  color: #7a7a7a;
  line-height: 58px;
}

.years-box-item {
  width: 20%;
}

.year-circle {
  position: relative;
  width: 139px;
  height: 139px;
  border-radius: 50%;
  background-color: #4c4c4c;
  line-height: 139px;
  transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.year-circle:hover {
  background-color: #f96f2f;
  transform: scale(1.14);
}

.year-circle::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 118px;
  height: 118px;
  border-radius: 50%;
  border: solid 1px #ffffff;
}

.year-content {
  margin: 50px 0;
  padding: 0 15px;
  font-size: 18px;
  font-family: Arial;
  color: #a0a0a0;
  line-height: 30px;
}

.data-box {
  justify-content: space-between;
}

.data-item {
  line-height: 1.15;
}

.data-item-title {
  font-size: 50px;
  font-family: Saira;
  font-weight: 900;
  color: #f96f2f;
}

.data-item-num {
  margin: 50px 0 100px;
  font-size: 50px;
  font-family: Saira;
  font-weight: 900;
  color: #4c4c4c;
}

.slider {
  width: 100vw;
  background: #000;
  line-height: 1.15;
  overflow: hidden;
}

.slider-mobile {
  margin-top: 30px;
  width: 100vw;
  background: #000;
  overflow: hidden;
  display: none;
}

.slider-contain {
  min-width: 100vw;
}

.slider-title {
  padding: 28px 0;
  font-size: 60px;
  font-family: Saira;
}

.slider-ftitle {
  font-size: 36px;
}

.slider-main {
  padding: 30px 0;
}

.slider-left {
  padding: 30px 79px 30px 0;
  width: 577px;
  text-align: right;
}

// .slider-left::after {
//   position: absolute;
//   content: '';
//   right: 0;
//   top: 0;
//   width: 1px;
//   height: 100%;
//   background-color: #fff;
// }

.slider-left-title {
  margin-bottom: 20px;
  font-size: 26px;
  font-family: Saira;
}

.slider-left-floor {
  margin-top: 0px;
  font-size: 22px;
  font-family: Arial;
  color: #fff;
}

.slider-left-floor span {
  margin-right: 22px;
  font-weight: 700;
}

.slider-left-floor1 {
  margin: 90px 0 10px;
  font-family: Saira;
  color: #f36432;
}

.slider-right {
  // padding-left: 79px;
  // width: 577px;
}

.slider-right-box {
  width: 374px;
  height: 374px;
  border-radius: 50%;
  // background: #f36432;
}

.slider-right-box img {
  width: 317px;
  height: 317px;
  border-radius: 50%;
  object-fit: cover;
}

.arrow {
  top: 50%;
  cursor: pointer;
  z-index: 30;
  width: 20px;
  height: 20px;
  border-top: 4px solid hsla(0, 0%, 96.1%, .5);
  border-right: 4px solid hsla(0, 0%, 96.1%, .5);
}

.arrow:hover {
  border-top: 4px solid #fff;
  border-right: 4px solid #fff;
}

.left-icon {
  left: 25px;
  transform: translateY(-50%) rotate(-135deg);
}

.right-icon {
  right: 25px;
  transform: translateY(-50%) rotate(45deg);

}

.service-box {
  margin-top: 112px;

}

.service-item {
  width: 25%;
  height: 300px;
}

.service-item:nth-of-type(n + 5) {
  margin-top: 50px;
}

.service-item img {
  width: 171px;
  height: 162px;
  object-fit: contain;
}

.service-item span {
  font-size: 41px;
  font-family: ArialBlack;
  font-weight: 900;
  color: #454545;
}

.projects {
  margin: 50px 0 0;
  padding: 220px 0 0;
}

.dot-active {
  margin: 0 auto;
  width: 1px;
  height: 1px;
  position: relative;
  z-index: -1;

  .rectangle {
    top: 130px;
    left: -1500px;
    position: absolute;
    background-color: #d4d4d4;
    height: 160px;
    width: 3000px;
  }

  .rectangle1 {
    transform: rotate(-20deg);

    &::after {
      content: "";
      position: absolute;
      background-color: #d4d4d4;
      height: 160px;
      width: 5000px;
      right: -4822px;
      top: 850px;
      transform: rotate(20deg);
    }
  }

  .rectangle2 {
    transform: rotate(20deg);

    &::after {
      content: "";
      position: absolute;
      background-color: #d4d4d4;
      height: 160px;
      width: 5000px;
      transform: rotate(-20deg);
      left: -4822px;
      top: 850px;
    }
  }

  .triangle {
    width: 0;
    height: 0;
    position: absolute;
    top: 130px;
    left: -3900px;
    border-bottom: 1380px solid #d4d4d4;
    border-right: 3900px solid transparent;
    border-left: 3900px solid transparent;
  }

  .trianfoot {
    position: absolute;
    content: "";
    width: 100vw;
    height: 430px;
    left: -50vw;
    top: 760px;
    background-color: #d4d4d4;
    z-index: -10;
  }
}

.projects-logo {
  display: block;
  margin: 0 auto;
  width: 83px;
  height: 96px;
}

.grid-box {
  padding: 100px 0 200px;
  min-width: 980px;
}

.grid-box-mobile {
  padding: 40px 0 60px;
  display: none;
}

.grid-item {
  width: 780px;
  margin: 0 auto 0;
}

.grid-item:nth-of-type(2n) {
  width: 980px;
}

.grid-item:nth-of-type(n + 2) {
  margin: -20px auto 0;
}


.grid-item-over {
  width: 140px;
  height: 140px;
  background-color: #fff;
  transform: rotate(45deg);
  border-radius: 16px;
  overflow: hidden;
  transition: .3s ease;
}

.grid-item-over:hover {
  transform: scale(1.14) rotate(45deg);
}

.grid-item-over:nth-of-type(n + 2) {
  margin-left: 60px;
}

.grid-item-child {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 196px;
  height: 196px;
  object-fit: contain;
  transition: .3s ease;
}

.partner {
  padding-bottom: 100px;
}

.partner-item {
  margin-top: 50px;
  width: 33.33%;
  height: 150px;
}

.partner-item-img {
  width: 300px;
  object-fit: contain;
}

@media screen and (max-width:750px) {
  .swiper {
    height: calc(100vh);
  }

  .banner1 {
    right: -36px;
    width: 242px;
    height: 116px;
  }

  .banner2 {
    top: -25px;
    width: 317px;
    height: 46px;
  }

  .home-box {
    width: 305px;
  }

  .home-title {
    padding: 25px 0 0;
    font-size: 20px;
  }

  .home-tips {
    font-size: 12px;
    line-height: 16px;
  }

  .years-box-item {
    width: 50%;
  }

  .year-circle {
    width: 67px;
    height: 67px;
    line-height: 67px;
    font-size: 15px;
    cursor: none;
  }

  .year-circle::after {
    width: 59px;
    height: 59px;
  }

  .year-content {
    margin: 20px 0;
    padding: 0;
    font-size: 11px;
    line-height: 1.15;
    cursor: none;
  }

  .data-item {
    width: 50%;
    height: 61px;
    justify-content: center;
  }

  .data-item-title,
  .data-item-num {
    font-size: 15px;
  }

  .data-item-num {
    margin: 5px 0 0;
  }

  .slider {
    display: none;
  }

  .slider-mobile {
    display: block;
  }

  .slider-contain {
    padding: 30px;
  }

  .slider-title {
    padding: 0;
    font-size: 18px;
  }

  .slider-ftitle {
    font-size: 12px;
  }

  .slider-right-box {
    margin: 0px 0 22px 0;
    width: 174px;
    height: 174px;
  }

  .slider-right-box img {
    width: 145px;
    height: 145px;
  }

  .slider-left-title,
  .slider-left-floor1 {
    font-size: 15px;
  }

  .slider-left-title {
    margin-bottom: 10px;
  }

  .slider-left-floor {
    margin-top: 4px;
    font-size: 11px;
  }

  .slider-left-floor1 {
    margin: 25px 0 0;
  }

  .service {
    width: 305px;
  }

  .service-box {
    margin-top: 0;
  }

  .service-item {
    margin-top: 40px;
    width: 50%;
    height: 70px;
  }

  .service-item img {
    width: 40px;
    height: 42px;
  }

  .service-item span {
    font-size: 11px;
  }

  .projects {
    padding: 40px 0 0;
    margin: 40px 0 0;
  }

  .dot-active {
    .rectangle {
      top: 60px;
      height: 50px;
    }

    .triangle {
      top: 60px;
    }
  }

  .projects-logo {
    width: 40px;
    height: 46px;
  }

  .grid-box {
    display: none;
  }

  .grid-box-mobile {
    display: block;
  }

  .grid-item {
    width: 200px;
  }

  .grid-item:nth-of-type(2n) {
    width: 274px;
  }

  .grid-item:nth-of-type(n + 2) {
    margin: -10px auto 0;
  }

  .grid-item-over {
    width: 49px;
    height: 49px;
    border-radius: 6px;
  }

  .grid-item-over:nth-of-type(n + 2) {
    margin-left: 25px;
  }

  .grid-item-child {
    width: 65px;
    height: 65px;
  }

  .partner-main {
    width: 310px;
  }

  .partner {
    padding-bottom: 20px;
  }

  .partner-item {
    margin-top: 20px;
    height: auto;
  }

  .partner-item-img {
    width: 70px;
  }
}
</style>
