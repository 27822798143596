import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
}, {
    path: '/Events',
    name: 'Events',
    component: () =>
        import ('../views/Events.vue')
}, {
    path: '/Activities',
    name: 'Activities',
    component: () =>
        import ('../views/Activities.vue')
}, {
    path: '/Works',
    name: 'Works',
    component: () =>
        import ('../views/Works.vue')
}, {
    path: '/Recruit',
    name: 'Recruit',
    component: () =>
        import ('../views/Recruit.vue')
}, {
    path: '/Train',
    name: 'Train',
    component: () =>
        import ('../views/Train.vue')
}, {
    path: '/Detail',
    name: 'Detail',
    component: () =>
        import ('../views/Detail.vue')
}, {
    path: '/AcDetail',
    name: 'AcDetail',
    component: () =>
        import ('../views/AcDetail.vue')
}, {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () =>
        import ('../views/AboutUs.vue')
}, {
    path: '/Introduce',
    name: 'Introduce',
    component: () =>
        import ('../views/Introduce.vue')
}]

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router