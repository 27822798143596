const addScrollListener = arr => {
    var w_height = window.innerHeight
    var classArr = []
    for (var i = 0; i < arr.length; i++) {
        classArr.push(document.querySelectorAll(arr[i]))
    }
    window.addEventListener('scroll', (e) => {
        for (var j = 0; j < classArr.length; j++) {
            var type = arr[j].match(/\d+/)[0]

            var animationName
            switch (type) {
                case '1':
                case '5':
                    animationName = 'aos-fade-in'
                    break;
                case '2':
                    animationName = 'aos-scale-in'
                    break;
                case '3':
                case '4':
                    animationName = 'aos-x-in'
                    break
            }
            var target = classArr[j]
            for (var i = 0; i < target.length; i++) {
                if (target[i].offsetTop - window.pageYOffset < w_height * 0.8) {
                    target[i].classList.add(animationName)
                } else {
                    target[i].classList.remove(animationName)
                }
            }
        }
    }, true)
}
export default {
    addScrollListener
}