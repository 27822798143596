import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import api from '../common/util/index.js'

export default new Vuex.Store({
    state: {
        template: {
            cityPhotos: []
        },
        menuList: [{
            name: 'About Us',
            route: '/'
        }, {
            name: 'News',
            route: '/Introduce'
        }, {
            name: 'Works',
            route: '/Works'
        }, {
            name: 'Careers',
            route: '/Recruit'
        }, {
            name: 'Training',
            route: '/Train'
        }],
        menuCurrent: -1,
    },
    mutations: {
        setTemplate(state, template) {
            state.template = template
        }
    },
    actions: {
        //获取系统配置
        async getConfig({
            commit
        }) {
            var template = (await api.Get('index/getLogo')).data
            var footer = (await api.Get('index/advertisement?position=footer&page=1&limit=1')).data
            var cityPhotos = (await api.Get('index/advertisement?position=chengdu&page=1&limit=100000')).data
            var logoList = template.logo.logo.split(',')
            template.logoPc = logoList[0]
            template.logoMobile = logoList[1]
            template.footer = footer[0].image
            template.cityPhotos = cityPhotos
            commit('setTemplate', template)
        },
        //更改导航栏索引
        setNavbarCurrent({
            state
        }, path) {
            state.menuCurrent = state.menuList.findIndex(item => item.route === path)
        }
    }
})