import axios from "axios"
const request = (method, url, data = {}) => {
    axios.defaults.baseURL = '/api' //配置根域名
        //全局添加拦截器的作用是可以在每个api前面加上headers的token验证
    axios.interceptors.request.use(config => {
        // 判断token是否存在和是否需要token验证的路由
        if (sessionStorage.getItem('token')) {
            config.headers.Authorization = sessionStorage.getItem('token');
        }
        return config;
    })
    url = /^\//.test(url) ? url : `/${url}`
    return new Promise(function(resolve, reject) {
        axios({
            method,
            url,
            data
        }).then((response) => { //这里使用了ES6的语法
            resolve(response.data)
        }).catch((error) => {
            reject(error)
        })
    })
}

function Get(url, data) {
    return request('get', url, data)
}

function Post(url, data) {
    return request('post', url, data)
}
export default {
    Get,
    Post
}