import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.prototype.$store = store

Vue.config.productionTip = false

import api from './common/util/index.js'
Vue.prototype.$api = api
const header = 'http://maple.ruanmengkeji.cn'
Vue.prototype.$header = header

// Vue.filter('fillUrl', function(value) {
//     if (!value) return ''
//     return /http/.test(value) ? value : /^\//.test(value) ? header + value : header + `/${value}`
// })
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Message } from 'element-ui';
import { Loading } from 'element-ui';
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading
Vue.use(ElementUI, { zIndex: 9999999 });

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import { Swipe, SwipeItem } from 'vant';
import { Lazyload } from 'vant';
import { ImagePreview } from 'vant';

Vue.use(ImagePreview);
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')